<template>
  <div class="details">
    <div class="pointer header" @click="$emit('toggle', 'devices')">
      <h1>
        <div class="box-icon">
          <img src="../../assets/icons/settings/devices.png" alt="" />
        </div>
        Dispositivos Conectados
      </h1>
      <img
        class="pointer"
        src="../../assets/icons/settings/close.png"
        alt=""
        v-if="view.devices"
      />
      <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else />
    </div>
    <div class="content" v-if="view.devices">
      <div class="item">
        <div class="device">
          <div class="flex align-items-center">
            <img src="../../assets/icons/settings/device_card.png" alt="" />
            <p class="model">IPhone 14</p>
            <p class="online">Online</p>
          </div>
          <div class="flex align-items-center">
            <p class="local-time">São Paulo - Agora</p>
            <img class="status" src="../../assets/icons/settings/success.png" alt="" />
          </div>
        </div>
        <div class="device">
          <div class="flex align-items-center">
            <img src="../../assets/icons/settings/device_card.png" alt="" />
            <p class="model">Galaxy s20</p>
            <p class="offline">Offline</p>
          </div>
          <div class="flex align-items-center">
            <p class="local-time">Porto Alegre - Há 4 horas</p>
            <img class="status" src="../../assets/icons/settings/failed.png" alt="" />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button class="secondary" label="Denunciar acesso" />
        <Button label="Desconectar todos" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["view"],
  emits: ["toggle"],
  setup() {
    const options = ref({
      shared_content: false,
      partners_access: false,
    });
    return { options };
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 18px;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  .content {
    padding: 0 18px;
    .item {
      display: flex;
      flex-direction: column;
      padding: 18px 0;
      border-top: 1px solid #f2f2f2;
      .device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fafafa;
        border-radius: 10px;
        width: 100%;
        min-height: 48px;
        padding: 0 16px;
        .model {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #828282;
          margin-left: 12px;
        }
        .online {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #27ae60;
          margin-left: 8px;
        }
        .offline {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #bdbdbd;
          margin-left: 8px;
        }
        .local-time {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #bdbdbd;
        }
        .status {
          margin-left: 16px;
        }
        &:nth-child(n + 2) {
          margin-top: 5px;
        }
      }
    }
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.pointer {
  cursor: pointer;
}
.secondary {
  background: white;
  color: #ff4500;
  border: 1px solid white;
  box-shadow: none;
  &:hover {
    color: #ff4500;
    background: white;
    border: 1px solid white;
  }
}
.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
::v-deep(button) {
  width: max-content;
  padding: 0 12px;
  height: 40px;
  margin-bottom: 24px;
}
</style>
