<template>
  <div class="details">
    <div class="pointer header" @click="$emit('toggle', 'address')">
      <h1>
        <div class="box-icon">
          <img src="../../assets/icons/settings/address.png" alt="" />
        </div>
        Seus endereços
      </h1>
      <img
        class="pointer"
        src="../../assets/icons/settings/close.png"
        alt=""
        v-if="view.address"
      />
      <img
        class="pointer"
        src="../../assets/icons/settings/open.png"
        alt=""
        v-else
      />
    </div>
    <div class="content" v-if="view.address">
      <div class="item">
        <span class="p-input-icon-right p-fluid">
          <InputMask
            class="std-top"
            v-model="address.cep"
            mask="99999-999"
            icon="pi pi-question-circle"
            placeholder="CEP"
          />
          <i
            class="pi pi-question-circle primary-color"
            v-tooltip.left="
              'Se não sabe seu CEP, selecione o estado e cidade para preencher o campo de endereço.'
            "
          />
        </span>
        <InputMask class="std-middle" v-model="address.estado" mask="a*" placeholder="ESTADO" />
        <InputText class="std-middle" v-model="address.cidade" placeholder="Cidade" />
        <InputText class="std-middle" v-model="address.endereco" placeholder="Endereço" />
        <InputText class="std-middle" v-model="address.numero" placeholder="Numero" />
        <InputText class="std-middle" v-model="address.complemento" placeholder="Complemento" />
        <InputText class="std-bottom" v-model="address.bairro" placeholder="Bairro" />
        <div class="flex justify-content-end mt-24">
          <Button class="btn-gray" label="Atualizar endereço" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["view"],
  emits: ["toggle"],
  setup() {
    const address = ref({
      cep: "88345-678",
      estado: "SC",
      cidade: "Florianópolis",
      endereco: "Av. Juscelino Kubitschek",
      numero: "1234",
      complemento: "apto. 404",
      bairro: "Centro"
    })
    const options = ref({
      shared_content: false,
      partners_access: false,
    });
    return { address, options };
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 18px;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  .content {
    padding: 0 18px;
    .item {
      display: flex;
      flex-direction: column;
      padding: 18px 0;
      border-top: 1px solid #f2f2f2;
      p {
        color: #4f4f4f;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      .right {
        display: flex;
        align-items: center;
        gap: 29px;
        margin-right: 6px;
      }
    }
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.pointer {
  cursor: pointer;
}
.btn-gray {
  background: #e0e0e0;
  color: #828282;
  border: 1px solid #e0e0e0;
  &:hover {
    background: #e0e0e0;
    color: #828282;
    border: 1px solid #e0e0e0;
  }
}
.mt-24{
  margin-top: 24px;
}
.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
::v-deep(.p-input-icon-right) {
  width: 100% !important;
}
::v-deep(input) {
  width: 100% !important;
  height: 50px;
}
::v-deep(button) {
  width: max-content;
  padding: 0 16px;
  height: 40px;
}
::v-deep(.std-middle) {
  border-bottom: 1px solid white;
}
::v-deep(.std-bottom) {
  border-top: 1px solid #ced4da;
}
</style>