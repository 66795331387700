<template>
  <div class="details">
    <div class="pointer header" @click="$emit('toggle', 'password')">
      <h1>
        <div class="box-icon">
          <img src="../../assets/icons/settings/password.png" alt="" />
        </div>
        Gerenciar Senha
      </h1>
      <img
        class="pointer"
        src="../../assets/icons/settings/close.png"
        alt=""
        v-if="view.password"
      />
      <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else />
    </div>
    <div class="content" v-if="view.password">
      <!-- TODO: PASSWORD -->
      <div class="inputs" v-if="screen === 'password'">
        <Password
          :feedback="false"
          class="atual"
          placeholder="Senha atual"
          v-model="password.atual"
          @input="testPassword"
          toggleMask
          maxlength="50"
          onpaste="return false"
          ondrop="return false"
        />
        <p
          v-if="
            current_correct.status && password.atual.length > 0 && !current_correct.value
          "
          style="color: red"
        >
          A senha está incorreta
        </p>
        <Password
          :feedback="false"
          class="new std-input-down"
          v-model="password.nova"
          placeholder="Nova senha"
          toggleMask
          maxlength="50"
          onpaste="return false"
          ondrop="return false"
        />
        <Password
          :feedback="false"
          class="confirm std-input-down"
          v-model="password.confirm"
          placeholder="Confirmar senha"
          toggleMask
          maxlength="50"
          onpaste="return false"
          ondrop="return false"
        />
        <div class="flex flex-column" style="margin-top: 16px">
          <k-progress
            class="k-progress progress"
            :percent="strength.valor"
            :cut-width="2"
            :show-text="false"
            :color="strength.cor"
            :line-height="8"
          />
          <p class="boa-senha">Não esqueça de criar uma boa senha</p>
        </div>

        <div class="flex justify-content-end mt-3">
          <Button class="secondary" label="Esqueci a Senha" />
          <Button class="primary" label="Atualizar Senha" @click="screen = 'sms'" />
        </div>
      </div>
      <!-- TODO: SMS -->
      <div class="inputs center-content" v-if="screen === 'sms'">
        <p class="txt-gray">Enviamos um SMS com código de confirmação</p>
        <p class="txt-gray">para celular <b>”+55 51 980155536”.</b></p>
        <p class="txt-gray">Digite esse código abaixo para</p>
        <p class="txt-gray">mudar sua nova senha.</p>
        <InputMask
          class="sms-code"
          mask="999999"
          placeholder="XXXXXX"
          v-model="code"
          @blur="changeSmsCode"
        />
        <b class="text-center pointer p-3" style="color: #ff4500"
          >Enviar Código Novamente</b
        >
      </div>
      <!-- TODO: SUCEESS -->
      <div class="inputs center-content" v-if="screen === 'success'">
        <div class="flex align-items-center justify-content-center p-3">
          <img src="../../assets/icons/settings/success.png" alt="" />
          <p class="txt-success">Sua senha foi alterada com sucesso</p>
        </div>
        <b class="text-center pointer p-3" style="color: #ff4500" @click="finish"
          >Finalizar</b
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
let strongPassword = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%^&*()]))|((?=.*[a-z])(?=.*[A-Z]))((?=.*[0-9])))(?=.{6,})"
);
let mediumPassword = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[^a-z])))(?=.{6,})"
);

export default {
  props: ["view"],
  emits: ["toggle"],
  setup() {
    const code = ref(false);
    const screen = ref("password");
    const current_correct = ref({
      status: false,
      value: false,
    });
    const options = ref({
      shared_content: false,
      partners_access: false,
    });

    const password = ref({
      atual: "",
      nova: "",
      nova_confirm: "",
      current_correct: false,
    });

    const testPassword = () => {
      if (password.value.atual.length > 0) {
        if (password.value.atual === "123456") {
          password.value.current_correct = true;
          current_correct.value.status = true;
          current_correct.value.value = true;
        } else {
          current_correct.value.status = true;
          current_correct.value.value = false;
        }
      }
    };

    const changeSmsCode = () => {
      console.log(code.value === 111111);
      if (code.value == "111111") {
        screen.value = "success";
        code.value = "";
      }
    };

    const finish = () => {
      screen.value = "password";
      view.value = false;
    };

    return {
      code,
      current_correct,
      testPassword,
      options,
      password,
      finish,
      changeSmsCode,
      strength: computed(() => {
        let value = 0;
        let color = "";

        if (strongPassword.test(password.value.nova)) {
          value = 100;
          color = "green";
        } else if (mediumPassword.test(password.value.nova)) {
          value = 66;
          color = "yellow";
        } else if (password.value.nova.length) {
          value = 33;
          color = "red";
        }
        let config = {
          valor: value,
          cor: color,
        };
        return config;
      }),
      screen,
    };
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 18px;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  .content {
    padding: 0 18px;
    padding-bottom: 18px;
    .item {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
      border-top: 1px solid #f2f2f2;
      p {
        color: #4f4f4f;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      .right {
        display: flex;
        align-items: center;
        gap: 29px;
        margin-right: 6px;
      }
    }
    .inputs {
      display: flex;
      flex-direction: column;
      min-height: 290px;
      .p-inputtext .p-component .p-password-input .p-password-input {
        input {
          width: 100% !important;
        }
      }
      .atual {
        height: 55px;
        width: 100% !important;
        border-radius: 8px;
        margin-bottom: 8px;
      }
      .new {
        height: 55px;
      }
      .confirm {
        height: 55px;
      }
      button {
        width: max-content;
        height: 40px;
      }
      .primary {
        background: #e0e0e0;
        color: #828282;
        border: none;
        box-shadow: none;
      }
      .secondary {
        background: white;
        color: #ff4500;
        border: none;
        box-shadow: none;
      }
      .boa-senha {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        color: #828282;
      }
      .txt-red {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        color: #eb5757;
      }
      .txt-green {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        color: #219653;
      }
      .txt-gray {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #828282;
        text-align: center;
      }
      .txt-success {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #828282;
        margin-left: 8px;
      }
    }
  }
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  width: max-content;
  height: 40px;
}
.pointer {
  cursor: pointer;
}
.k-progress {
  width: 100% !important;
}
.progress {
  width: 100% !important;
}
.k-progress-outer-bg,
.k-progress-outer-bg-border {
  width: 100% !important;
  border: 1px red solid;
}
.k-progress-outer-bg {
  width: 100% !important;
}
.k-progress {
  margin-bottom: 0px !important;
}
.k-progress-outer {
  padding-right: 0px !important;
  padding: 0 !important;
}
.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
::v-deep(input) {
  border-radius: 8px;
}
::v-deep(.sms-code) {
  width: 200px;
  height: 40px;
  margin: 0 auto;
  margin-top: 15px;
}
::v-deep(.sms-code, input) {
  text-align: center;
}
::v-deep(.k-progress-outer) {
  width: 100%;
}
</style>
