<template>
    <div class="your-health-datails">
        <div class="flex align-items-center gap-16">            
            <img src="../../assets/icons/settings/your-health-datails.png" alt="" />
            <h1>Detalhes da sua saúde</h1>
        </div>
        <div class="flex align-items-center gap-16">
            <p>Suas informações e hábitos</p>
            <img src="../../assets/icons/arrow-right.png" alt="" />
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.your-health-datails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    background: #FF6A33;
    border-radius: 8px;
    min-height: 64px;
    padding: 24px 26px;
    cursor: pointer;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: white;
        margin: 0;
        padding: 0;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: white;
    }
}

// ::v-deep(.) {

// }
</style>