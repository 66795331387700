<template>
    <div class="profile">
        <div class="flex align-items-center gap-26">
            <img class="img-profile" src="@/assets/avatares/patients/rubens.png" alt="" />
            <h1>Rubens Melo</h1>
            <div class="flex align-items-center gap-12 tags">
                <div class="item">
                    <img src="../../assets/icons/settings/heart.png" alt="" />
                    Casado
                </div>
                <div class="item">
                    <img src="../../assets/icons/settings/save.png" alt="" />
                    Engenheiro civil
                </div>
                <div class="item">
                    <img src="../../assets/icons/settings/map-location.png" alt="" />
                    São Paulo, SP
                </div>
            </div>
        </div>
        <div class="edit cursor-pointer">
            <img src="../../assets/icons/settings/edit.png" alt="" />
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 170px;
    background: radial-gradient(ellipse at bottom, rgba(205,219,239,1) 10%, rgba(237,237,237,1) 30%);
    backdrop-filter: blur(81.5485px);
    border-radius: 8px;
    padding: 45px 25px;
    margin-bottom: 22px;
    margin-top: 36px;
    .img-profile {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .tags{
        margin-left: 34px;
        .item{
            display: flex;
            align-items: center;
            gap: 6px;
            background: #F1F5F8;
            border-radius: 32px;
            width: max-content;
            padding: 8px 12px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #2D313D;
        }
        
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #4F4F4F;
        margin: 0;
        padding: 0;
    }
    .edit{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FF6A33;
    }
}

// ::v-deep(.) {

// }
</style>